<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo/>
                <h2 class="brand-text text-primary ml-1">
                    Razrez
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="8"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Login V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                lg="4"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1 text-center"
                    >
                        {{ $t("login.greeting") }} 👋
                    </b-card-title>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent
                        >
                            <!-- email -->
                            <validation-provider rules="required" #default="{ errors }">
                                <b-form-group label="Email" rules="required|email" label-for="login-email"
                                              invalid-feedback="Email je obvezen" :state="errors[0] ? false:null">
                                    <b-form-input id="login-email" v-model="userEmail" name="login-email" placeholder="john@example.com"
                                                  :state="errors[0] ? false:null"/>
                                </b-form-group>
                            </validation-provider>

                            <!-- password -->
                            <b-form-group>
                                <validation-provider rules="required" #default="{ errors }">
                                    <b-form-group label="Geslo" rules="required" label-for="login-password"
                                                  invalid-feedback="Geslo je obvezno" :state="errors[0] ? false:null">
                                        <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                            <b-form-input id="login-password" v-model="password" :type="passwordFieldType"
                                                          name="login-password" placeholder="············" :state="errors[0] ? false:null"/>
                                            <b-input-group-append is-text>
                                                <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                                                              @click="togglePasswordVisibility"/>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox id="remember-me" v-model="rememberMe" name="checkbox-1">
                                    {{ $t("login.rememberMe") }}
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button type="submit" variant="primary" block @click="validationForm">
                                {{ $t("login.login") }}
                            </b-button>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>
<script>
/* eslint-disable global-require */
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {
        BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardTitle, BForm, BButton
    } from 'bootstrap-vue'
    import {required, email} from '@validations'
    import {togglePasswordVisibility} from '@core/mixins/ui/forms'
    import store from '@/store/index'

    export default {
        components: {
            BLink,
            BFormGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroup,
            BFormCheckbox,
            BCardTitle,
            BForm,
            BButton,
            VuexyLogo,
            ValidationProvider,
            ValidationObserver
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                password: '',
                userEmail: '',
                rememberMe: false,
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                // validation rulesimport store from '@/store/index'
                required,
                email,
                roleId: '',
                userData: {'id': '', 'name': '', 'email': '', 'role': {id: '', permissions: [], title: ''}}
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            }
        },
        methods: {
            validationForm() {
                this.$refs.loginValidation.validate().then(() => {
                    this.loginJWT()
                })
            },
            async loginJWT() {
                const thisIns = this
                thisIns.$store.state.app.showLoader = true
                this.$http
                    .post('/api/v1/user/login', {
                        email: thisIns.userEmail,
                        password: thisIns.password,
                        remember_me: thisIns.rememberMe
                    })
                    .then(async function(response) {
                        thisIns.roleId = response.data.role
                        thisIns.userData.id = response.data.id
                        thisIns.userData.name = response.data.name
                        thisIns.userData.email = response.data.email
                        thisIns.userData.role.id = response.data.role
                        thisIns.userData.role.permissions = response.data.permissions
                        thisIns.$store.dispatch('user/login', response.data.token)
                        thisIns.$store.dispatch('user/setUserData', thisIns.userData)
                    })
                    .catch(function() {
                        thisIns.$printWarning(thisIns.$t('login.wrong_password'))
                    })
                    .finally(function() {
                        thisIns.$store.state.app.showLoader = false
                    })
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
